<template>
  <div>
    <hero />

    <contents />
  </div>
</template>

<script>
  export default {
    components: {
      Hero: () => import('@/components/blog/Hero'),
      Contents: () => import('@/components/blog/Contents')
    }
  }
</script>
